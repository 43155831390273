import React, { useState } from "react"
import { RouteProps } from "react-router-dom"
import RetrievePasswordDialog from "./RetrievePasswordDialog"
import WeightplanPeopleWrapper from "../WeightplanPeopleWrapper"

const Home: React.FC<RouteProps> = () => {
  const [showRetrievePasswordDialog, setShowRetrievePasswordDialog] = useState(
    false
  )

  const closeRetrievePasswordDialog = () => {
    setShowRetrievePasswordDialog(false)
  }

  const handleShowSendPassword = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault()
    setShowRetrievePasswordDialog(true)
  }

  return (
    <WeightplanPeopleWrapper>
      <h1>Welcome</h1>

      <p>
        We're working to bring you an all new Weightplan website. In the
        meantime, take a look at our{" "}
        <a
          href="http://itunes.apple.com/us/app/gym-training-by-weightplan.com/id445611256?ls=1&mt=8"
          style={{ whiteSpace: "nowrap" }}
        >
          GymTraining Personal Trainer
        </a>{" "}
        fitness app.
      </p>

      <h2>Forgot your password?</h2>

      <p>
        <a href="#" onClick={handleShowSendPassword}>
          Request a password reset email
        </a>
      </p>

      {showRetrievePasswordDialog && (
        <RetrievePasswordDialog requestClose={closeRetrievePasswordDialog} />
      )}
    </WeightplanPeopleWrapper>
  )
}

export default Home
