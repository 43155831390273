import { createGlobalStyle } from "styled-components"
import museoSlab900 from "./assets/fonts/Museo-Slab-900.otf"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: MuseoSlab900;
    src: url(${museoSlab900});
    font-weight: 700;
    font-stretch: normal;
    units-per-em: 256;
  }

  body {
    min-width: 1024px !important;
    overflow-x: unset !important;
  }

  h3 {
    font-family: MuseoSlab900;
  }

  a:hover, a:active, a:focus {
    outline: none;
  }

  .clear {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ".";
  }

  .sub16 {
    font-family: MuseoSlab900;
    color: #cd0769;
    font-size: 18px;
    font-weight: bold;
    padding: 4px;
    margin: 0;
  }

  .blueButton {
    border: 0;
    border-radius: 5px;
    color: white !important;
    font-size: 10pt;
    background-color: #4cb7e6;
    padding: 0.5em 1.5em;
    text-decoration: none;
    margin-top: 2px;
    margin-right: 10px;
    white-space: nowrap;
  }

  .blueButton:hover {
      background-color: #8ed3f2;
  }

  .noborderLeft {
    border-left: none !important;
  }

  .noborderRight {
    border-right: none !important;
  }

  .noborder {
    border: none !important;
  }
`

export default GlobalStyle
