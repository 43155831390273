import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import GymTrainingMobileWrapper from "./containers/GymTrainingMobileViews/GymTrainingMobileWrapper"
import WeightplanWrapper from "./containers/WeightplanWrapper"
import "./semantic/dist/semantic.min.css"
import "./utils/validation"

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/GymTrainingMobileViews"
        component={GymTrainingMobileWrapper}
      />
      <Route component={WeightplanWrapper} />
    </Switch>
  </BrowserRouter>
)

export default App
