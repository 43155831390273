import queryString from "query-string"
import React from "react"
import { Helmet } from "react-helmet"
import { Redirect, Route, Switch } from "react-router-dom"
import styled from "styled-components"
import facebookImage from "../../assets/images/facebook.png"
import footerBackground from "../../assets/images/footer_background.png"
import headerBackgroundImage from "../../assets/images/header_background.png"
import iPhoneImage from "../../assets/images/iphone_logo.png"
import logoImage from "../../assets/images/logo.png"
import twitterImage from "../../assets/images/twitter.png"
import youTubeImage from "../../assets/images/youtube.png"
import GlobalStyle from "../../GlobalStyle"
import Home from "../Home"
import NotFound from "../NotFound"
import ResetPassword from "../ResetPassword"
import WeightplanPeopleWrapper from "../WeightplanPeopleWrapper"

const IPhoneLink = styled.a`
  display: inline-block;
  margin-right: 35px;
`

const SocialLink = styled.a`
  display: inline-block;
  margin: 0 4px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 980px;
  height: 84px;
  margin: 0 0 10px 25px;
  padding: 10px 0 10px 0;
`

const Logo = styled.div`
  a {
    display: inline-block;
    width: 189px;
    height: 60px;
    background-image: url(${logoImage});
  }
`

const TopRightMenu = styled.div`
  font-size: 8pt;
  color: White;
  text-align: right;

  a,
  a:hover,
  a:visited {
    color: White;
    text-decoration: underline;
  }
`

const ParentContainer = styled.div`
  background-repeat: repeat-x;
  background-image: url(${headerBackgroundImage});
`

const Container = styled.div`
  width: 1014px;
  margin: 0 auto;
`

const MainContentContainer = styled.div`
  clear: both;
  margin-bottom: 27px;
  padding: 27px 0;
  width: 1014px;
  border-radius: 10px;
  background-color: #fff;
`

const FooterText = styled.div`
  text-align: center;
  font-size: 8pt;
  color: #a1c2d4;

  a {
    text-decoration: none;
    color: #a1c2d4;
  }

  a:hover {
    color: White;
  }
`

const Footer = styled.div`
  background-image: url(${footerBackground});
  padding-bottom: 5px;
`

const WeightplanWrapper: React.FC = () => {
  const openFaq = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    window.open(
      "https://www.weightplan.com/faq.html",
      "help",
      "width=1050, height=768, scrollbars=yes, toolbars=no"
    )
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta name="viewport" content="width=1024" />
      </Helmet>
      <ParentContainer>
        <Container>
          <Header>
            <Logo>
              <a href="https://www.weightplan.com/" />
            </Logo>
            <TopRightMenu>
              <React.Fragment>
                <span className="contact_us">
                  <a href="https://www.weightplan.com/support.html">
                    Contact Us
                  </a>
                </span>
                {" | "}
                <a href="#" onClick={openFaq}>
                  Help
                </a>
              </React.Fragment>
            </TopRightMenu>
          </Header>
          <MainContentContainer>
            <Switch>
              <Redirect path="/Home/LogOn" to="/" />
              <Route
                path="/Home/ResetPassword"
                render={(props) => {
                  const query = queryString.parse(props.location.search)
                  return (
                    <Redirect
                      to={{
                        pathname: "/reset-password",
                        search: queryString.stringify({
                          id: query.Id,
                          token: query.Token,
                        }),
                      }}
                    />
                  )
                }}
              />
              <Route path="/reset-password" component={ResetPassword} />
              <Route exact path="/" component={Home} />
              <Route
                render={() => {
                  return (
                    <WeightplanPeopleWrapper>
                      <NotFound />
                    </WeightplanPeopleWrapper>
                  )
                }}
              />
            </Switch>
          </MainContentContainer>
          <br className="clear" />
        </Container>
        <Footer>
          <div
            style={{
              float: "none",
              clear: "both",
              width: 1034,
              paddingTop: 10,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
            }}
          >
            <div style={{ flexGrow: 1, width: 0 }} />
            <div style={{ flexGrow: 1, width: 0 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 2,
                }}
              >
                <SocialLink href="http://www.facebook.com/pages/Weightplancom/104573302937711">
                  <img src={facebookImage} alt="Facebook" />
                </SocialLink>
                <SocialLink href="http://twitter.com/#!/weightplanLtd">
                  <img src={twitterImage} alt="Twitter" />
                </SocialLink>
                <SocialLink href="http://www.youtube.com/user/Weightplan">
                  <img src={youTubeImage} alt="YouTube" />
                </SocialLink>
              </div>
              <FooterText>
                &copy;{new Date().getFullYear()} Weightplan.com{" "}
                <a href="https://www.weightplan.com/terms.html">
                  Terms &amp; Conditions
                </a>{" "}
                |{" "}
                <a href="https://www.weightplan.com/privacy.html">
                  Privacy policy
                </a>
              </FooterText>
            </div>
            <div style={{ flexGrow: 1, width: 0, textAlign: "right" }}>
              <IPhoneLink href="http://itunes.apple.com/us/app/gym-training-by-weightplan.com/id445611256?ls=1&mt=8">
                <img src={iPhoneImage} alt="App Store" />
              </IPhoneLink>
            </div>
          </div>
        </Footer>
      </ParentContainer>
    </React.Fragment>
  )
}

export default WeightplanWrapper
