import React from "react"
import { RouteProps } from "react-router-dom"

const NotFound: React.FC<RouteProps> = () => (
  <React.Fragment>
    <h3>
      Page not found: <code>{window.location.pathname}</code>
    </h3>

    <p>
      Return to the <a href="/">homepage</a>.
    </p>
  </React.Fragment>
)

export default NotFound
