import React from "react"
import styled from "styled-components"
import closeButtonImage from "../../assets/images/btn_Close02.png"
import Dialog, { CloseButton } from "../Dialog"

export interface IWeightplanDialogButton {
  name: string
  action: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

interface IProps {
  body: string | JSX.Element | null
  buttons: IWeightplanDialogButton[] | null

  requestClose: () => void
}

const NotificationDialog = styled.div`
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #ffffff;
  border: 5px solid #156793;
`

const DialogPadding = styled.div`
  position: relative;
  margin: 10px;
`

const DialogBody = styled.div`
  position: relative;
  float: none;
  clear: both;
  margin: 10px;
  text-align: center;
`

const DialogButtons = styled.div`
  height: 30px;
  width: 100%;
  text-align: center;
`

const NewBlueButton = styled.a`
  display: inline-block !important;
  height: 29px;
  background-color: #4cb6e6;
  width: 80px;
  text-align: center;
  text-decoration: none;
  margin-top: 2px;
  margin-right: 10px;
  border-radius: 5px;
  color: white !important;
  line-height: 29px;

  :hover {
    background-color: #8ed3f2;
  }
`

const WeightplanDialog: React.FC<IProps> = ({
  body,
  buttons,
  requestClose,
}) => {
  const handleCloseLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    requestClose()
  }

  const handleClose = () => {
    requestClose()
  }

  return (
    <Dialog requestClose={handleClose}>
      <NotificationDialog>
        <CloseButton>
          <a href="#" onClick={handleCloseLink}>
            <img src={closeButtonImage} />
          </a>
        </CloseButton>
        <DialogPadding>
          <DialogBody>{body}</DialogBody>
          <DialogButtons>
            {(buttons != null
              ? buttons
              : [{ name: "OK", action: handleCloseLink }]
            ).map((button) => (
              <NewBlueButton key={button.name} href="#" onClick={button.action}>
                {button.name}
              </NewBlueButton>
            ))}
          </DialogButtons>
        </DialogPadding>
      </NotificationDialog>
    </Dialog>
  )
}

export default WeightplanDialog
