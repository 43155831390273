import React from "react"
import errorLeft from "../../assets/images/errorLeft.jpg"
import errorRight from "../../assets/images/errorRight.jpg"

const WeightplanPeopleWrapper: React.FC = ({ children }) => (
  <div style={{ margin: "0 100px 20px 100px", display: "flex" }}>
    <div>
      <img src={errorLeft} alt="" style={{ margin: "auto" }} />
    </div>
    <div style={{ width: 350, margin: "auto" }}>{children}</div>
    <div>
      <img src={errorRight} alt="" style={{ margin: "auto" }} />
    </div>
  </div>
)

export default WeightplanPeopleWrapper
