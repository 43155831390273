import React from "react"
import { createGlobalStyle } from "styled-components"
import { Switch, Route } from "react-router"
import TermsAndConditions from "./TermsAndConditions"
import Privacy from "./Privacy"
import NotFound from "../NotFound"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fff !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    margin: 8px !important;
  }
`

const GymTrainingMobileWrapper: React.FC = () => (
  <React.Fragment>
    <GlobalStyle />
    <Switch>
      <Route
        path="/GymTrainingMobileViews/TermsAndConditions"
        component={TermsAndConditions}
      />
      <Route path="/GymTrainingMobileViews/Privacy" component={Privacy} />
      <Route component={NotFound} />
    </Switch>
  </React.Fragment>
)

export default GymTrainingMobileWrapper
