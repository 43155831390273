import React from "react"

const Privacy: React.FC = () => (
  <React.Fragment>
    <h1>Privacy Policy</h1>

    <p>
      This privacy policy sets out how Weightplan Ltd. (trading as
      Weightplan.com, GymTraining.net and the app GymTraining by Weightplan.com)
      collects, uses and protects any of your personal data.
    </p>

    <h2>Interpretation</h2>

    <p>
      Headings do not form part of this policy and will not affect their
      interpretation. In these terms and conditions, "you" refers to the member
      and "we", "us" refers to Weightplan Limited. Weightplan.com is a website
      ("the site") and app ("the app") owned and operated wholly by us.
    </p>

    <h2>How we collect your data:</h2>

    <p>We may obtain your personal data either by:</p>

    <ul>
      <li>You registering an account at Weightplan.com</li>
      <li>You registering an account at GymTraining.net</li>
      <li>
        You registering an account on our app – GymTraining by Weightplan.com
      </li>
    </ul>

    <h2>Lawful Basis of Consent (GDPR 2018):</h2>

    <p>
      During the process of registering an account you will have been asked to
      confirm your lawful consent to your data being added to the Weightplan
      Ltd. database for the purposes of assisting you in losing weight,
      monitoring your dietary intake or gaining fitness. You will have provided
      email or other login details to create and access your profile.
    </p>

    <h2>What we collect:</h2>

    <ul>
      <li>contact information including email address, address, phone #</li>
      <li>
        basic profile information relating to name, height, weight, date of
        birth, gender, etc.
      </li>
      <li>information relating to your health &amp; fitness goals</li>
      <li>
        from time to time, other information relevant to customer surveys and/or
        offers
      </li>
      <li>
        from time to time, other information relating to your health &amp;
        fitness goals
      </li>
      <li>
        cookie and other preferences to assist in the navigation and use of the
        site or app
      </li>
    </ul>

    <h2>What we do with the information we gather:</h2>

    <ul>
      <li>We assist you in achieving your health &amp; fitness goals</li>
      <li>We process payment of membership fees</li>
      <li>
        We occasionally review data internally for our own operational purposes
      </li>
      <li>
        We assess effectiveness and improve the content of the site and apps
        including gathering broad demographic information;
      </li>
      <li>
        We customise the content and/or layout of our website for each
        individual member;
      </li>
      <li>
        We may send communications providing other information which we feel may
        assist you in the principle purpose assisting you in achieving your
        health &amp; fitness goals
      </li>
      <li>
        We may make your personal data available to other Weightplan Ltd.
        subscribers, affiliates, data processors and partners for the purpose of
        processing payments, managing our service to you and/or providing
        information or services that may support you in achieving your health
        &amp; fitness goals
      </li>
      <li>
        We may contact you by push notification, SMS, email, phone or mail where
        you have provided these contact details
      </li>
    </ul>

    <h2>How you can control your personal data:</h2>

    <ul>
      <li>
        You have the option of unsubscribing to non-transactional communications
        by clicking the 'unsubscribe' link at the bottom of any email received
        from us or by emailing{" "}
        <a href="mailto:support@weightplan.com">support@weightplan.com</a>
      </li>
      <li>
        We do not delete your data unless requested to do so by you by email to{" "}
        <a href="mailto:support@weightplan.com">support@weightplan.com</a>
      </li>
      <li>
        You may choose to restrict the use of your personal information whenever
        you are asked to fill in a form on the website, where available, by
        ticking or un-ticking the box to indicate that you do not want your
        information to be used for the specified purpose.
      </li>
      <li>
        You may request details of personal information which we hold about you
        under the Data Protection Act 1998 or the GDPR 2018. A small fee will be
        payable. If you would like a copy of the information held on you please
        email <a href="mailto:support@weightplan.com">support@weightplan.com</a>
        .
      </li>
      <li>
        If you believe that any information we are holding on you is incorrect
        or incomplete, please notify us by email at{" "}
        <a href="mailto:support@weightplan.com">support@weightplan.com</a>. We
        will promptly correct any information found to be incorrect.
      </li>
    </ul>

    <h2>Security</h2>

    <ul>
      <li>
        We will not sell, distribute or lease your personal information to third
        parties other than for the principle purpose of assisting you in
        providing information or services that may support you in achieving your
        health &amp; fitness goals unless we have your permission or are
        required by law to do so.
      </li>
      <li>
        We are committed to ensuring that your information is secure. In order
        to prevent unauthorised access or disclosure, we have put in place
        suitable physical, electronic and managerial procedures to safeguard and
        secure the information we collect online.
      </li>
    </ul>

    <h2>Automated information collection</h2>

    <ul>
      <li>
        Our web server automatically recognises the domain name and email
        address of each user of this website.
      </li>
      <li>
        We collect IP addresses as well as details of your browser type so we
        can deal with service problems we may also use it to deal with issues
        arising from breach of our terms and conditions of use of the sites.
      </li>
    </ul>

    <h2>Cookies</h2>

    <ul>
      <li>
        When you visit our website we send one or more "cookies" - small files
        containing a string of characters - to your computer that uniquely
        identifies your browser.
      </li>
      <li>
        We use cookies to improve the quality of our service by storing user
        preferences and tracking user trends. Most browsers are initially set up
        to accept cookies, but you can reset your browser to refuse all cookies
        or to indicate when a cookie is being sent. However, you will be unable
        to login and use all features of the site if your cookies are disabled.
      </li>
      <li>
        Our site uses a number of different cookies to tailor your experience
        when using the site and to help make the website as effective as it can
        be for our customers.
      </li>
    </ul>

    <h3>What is a cookie?</h3>

    <p>
      A cookie is a small text file that may sometimes be saved on your device
      when you visit a website. Cookies are used on most websites to tailor your
      experience e.g. we use a cookie to retain your login details so that it’s
      quicker and simpler to login the next time you visit us. When you next
      visit our website the cookie allows us to distinguish you from other
      users.
    </p>

    <p>
      There are two categories of cookies. Persistent cookies remain on your
      device until deleted manually or automatically. Session cookies remain on
      your device until you close your browser when they are automatically
      deleted by your browser.
    </p>

    <h3>Cookies we use</h3>

    <ul>
      <li>
        Essential cookies - Essential cookies are technical cookies that are
        required for the operation of our website. Without essential cookies our
        site can’t operate properly.
      </li>
      <li>
        Performance cookies - Performance cookies allow us to recognise and
        count the number of visitors to our site and to see how visitors move
        around. This helps us to improve the way our site works by enabling us
        to improve our site. The information we collect is aggregated which
        means that we cannot identify you from it.
      </li>
      <li>
        Experience cookies - Experience cookies allow our sites to remember the
        choices you make. Our sites use these to provide you with enhanced and
        personalised features. For example, we use information collected through
        “web-analytic” cookies to compare the choices you make to those of
        others so that we can improve. Information collected by experience
        cookies cannot track your browsing activity when you leave our site to
        browse other sites.
      </li>
    </ul>

    <h3>Disabling cookies and what happens if you do</h3>

    <p>
      The browsers of most computers and other web-enabled devices are typically
      set up to accept cookies. If you wish to amend your cookie preferences you
      can do this through your browser settings. Your browser's 'help' function
      will tell you how to do this. However, please remember that cookies are
      often used to enable and improve certain functions on websites. If you
      choose to switch cookies off, it is likely to affect how websites work.
      For example, if your browser is set to disable 'session' cookies, although
      you will still be able to view our public website, you won't be able to
      log on.
    </p>

    <p>
      If you carry on using our sites and do not change your browser settings we
      will assume you consent to us using cookies.
    </p>

    <h2>Your personal data</h2>

    <p>
      We advise you not to post information that can identify you (directly or
      indirectly). You need to be aware that if you choose to do so, it means
      that you are choosing to place your personal data in a worldwide
      environment where some jurisdictions do not have adequate laws to protect
      your data by European standards.
    </p>

    <h2>General</h2>

    <p>
      Weightplan Ltd. may change this policy from time to time by updating this
      page. You should check this page from time to time to ensure that you are
      happy with any changes. This policy is effective immediately.
    </p>

    <h2>Jurisdiction</h2>

    <p>
      Your Weightplan Ltd. information may be transferred outside of the
      European Economic Area (the "EEA"). Any transfers of your information
      outside of the EEA will be made in accordance with the Data Protection Act
      1998 and GDPR 2018.
    </p>

    <p>Version 07.04.18</p>
  </React.Fragment>
)

export default Privacy
