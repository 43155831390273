import { Form } from "formsy-semantic-ui-react"
import queryString from "query-string"
import React, { useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Button, Label } from "semantic-ui-react"
import { resetPassword as userResetPassword } from "../../utils/UserService"
import { passwordValidationRegexp } from "../../utils/validation"
import WeightplanPeopleWrapper from "../WeightplanPeopleWrapper"

enum PageState {
  Form,
  Success,
  Error,
}

const ResetPassword: React.FC<RouteComponentProps> = ({ location }) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const [pageState, setPageState] = useState(PageState.Form)

  const errorLabel = <Label color="red" pointing />

  const { id, token } = queryString.parse(location.search)

  const renderContent = () => {
    switch (pageState) {
      case PageState.Form:
        if (!id || !token) {
          return (
            <React.Fragment>
              <h1>Reset Password</h1>

              <p>
                The password reset request has either expired or is not valid.
              </p>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            <h1>Reset Password</h1>

            <Form
              onValidSubmit={handleResetPasswordSubmit}
              onValid={enableButton}
              onInvalid={disableButton}
            >
              <Form.Input
                type="password"
                name="password"
                label="Password"
                validations={{ matchRegexp: passwordValidationRegexp }}
                validationErrors={{
                  isDefaultRequiredValue: "Password is required",
                  matchRegexp:
                    "Password must be at least 6 characters long with at least one letter and number",
                }}
                errorLabel={errorLabel}
                required={true}
              />
              <Form.Input
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                validations={{ equalsField: "password" }}
                validationErrors={{
                  isDefaultRequiredValue: "Confirm Password is required",
                  equalsField: "Password and Confirm Password must match",
                }}
                errorLabel={errorLabel}
                required={true}
              />
              <Button type="submit" primary={true} disabled={!canSubmit}>
                Submit
              </Button>
            </Form>
          </React.Fragment>
        )
      case PageState.Success:
        return (
          <React.Fragment>
            <h1>Password Reset</h1>
            <p>Your password has been successfully reset.</p>
          </React.Fragment>
        )
      case PageState.Error:
        return (
          <React.Fragment>
            <h1>Error</h1>
            <p>
              Failed to reset password. Check all your details and{" "}
              <a href="#" onClick={handleTryAgainClick}>
                try again
              </a>
              .
            </p>
          </React.Fragment>
        )
    }
  }

  const disableButton = () => {
    setCanSubmit(false)
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const handleResetPasswordSubmit = (values: IResetPasswordFormData) => {
    resetPassword(values.password)
  }

  const handleTryAgainClick = () => {
    setPageState(PageState.Form)
  }

  const resetPassword = (password: string) => {
    userResetPassword(id as string, token as string, password)
      .then(() => {
        setPageState(PageState.Success)
      })
      .catch(() => {
        setPageState(PageState.Error)
      })
  }

  return <WeightplanPeopleWrapper>{renderContent()}</WeightplanPeopleWrapper>
}

interface IResetPasswordFormData {
  password: string
  resetPassword: string
}

export default withRouter(ResetPassword)
