import { Form } from "formsy-semantic-ui-react"
import React, { useState } from "react"
import { Button } from "semantic-ui-react"
import styled from "styled-components"
import closeButtonImage from "../../assets/images/btn_Close02.png"
import Dialog, { CloseButton } from "../../components/Dialog"
import WeightplanDialog, {
  IWeightplanDialogButton,
} from "../../components/WeightplanDialog"
import * as userService from "../../utils/UserService"

const RetrievePassword = styled.div`
  background-color: #ffffff;
  border: 5px solid #156793;
  border-radius: 10px;
  padding: 0;
  width: 360px;

  h4 {
    width: 350px;
    background-color: #4cb7e6;
    border-radius: 5px 5px 0 0;
    background-clip: padding-box;
    color: #fff;
    padding: 0 0 0 10px;
    margin: 0 0 0 0;
    *margin: -15px 0 0 0;
    height: 25px;
    line-height: 25px;
    font-weight: bold;
    z-index: 1;
    font-size: 12px;
  }
`

interface IProps {
  requestClose: () => void
}

// tslint:disable-next-line:max-classes-per-file
const RetrievePasswordDialog: React.FC<IProps> = ({ requestClose }) => {
  const [wpDialogBody, setWpDialogBody] = useState<string | JSX.Element | null>(
    null
  )
  const [wpDialogButtons, setWpDialogButtons] = useState<
    IWeightplanDialogButton[] | null
  >(null)
  const [wpDialogShow, setWpDialogShow] = useState<boolean>(false)

  const [canSubmit, setCanSubmit] = useState<boolean>(false)

  const resetState = () => {
    setWpDialogBody(null)
    setWpDialogButtons(null)
    setWpDialogShow(false)
    setCanSubmit(false)
  }

  const disableButton = () => {
    setCanSubmit(false)
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const handleCloseAll = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    resetState()
    close()
  }

  const handleRequestClose = () => {
    close()
  }

  const handleRequestCloseWPDialog = () => {
    resetState()
  }

  const handleRequestCloseClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    close()
  }

  const handleSendPasswordReminderSubmit = (
    values: IRetrievePasswordFormData
  ) => {
    sendPasswordReminder(values.email)
  }

  const close = () => {
    requestClose()
  }

  /**
   * Requests a password reset email
   * @param {string} email - The email address of the account which, if exists, should receive the reminder.
   */
  const sendPasswordReminder = (email: string) => {
    if (email === "") {
      setWpDialogBody("Please enter your email address")
      setWpDialogShow(true)
      return
    }

    userService
      .sendPasswordReminder(email)
      .then(() => {
        setWpDialogBody(
          <React.Fragment>
            <h3>Password sent</h3>A password reminder will shortly be sent to
            your email address.
            <br />
            <br />
            To ensure that your Weightplan emails get to your inbox, please add{" "}
            <a href="mailto:noreply@members.weightplan.com">
              noreply@members.weightplan.com
            </a>{" "}
            to your Address Book or Safe List.
          </React.Fragment>
        )
        setWpDialogButtons([{ name: "OK", action: handleCloseAll }])
        setWpDialogShow(true)
      })
      .catch(() => {
        setWpDialogBody(
          <p>
            We have encountered an error and could not send your password.
            Please contact{" "}
            <a href="mailto:support@weightplan.com">Weightplan support</a>.
          </p>
        )
        setWpDialogShow(true)
      })
  }

  return (
    <React.Fragment>
      <Dialog
        title="Request password reset email"
        requestClose={handleRequestClose}
      >
        <RetrievePassword>
          <CloseButton>
            <a href="#" onClick={handleRequestCloseClick}>
              <img src={closeButtonImage} />
            </a>
          </CloseButton>
          <h4>Request password reset email</h4>
          <div style={{ margin: 10 }}>
            <Form
              onValidSubmit={handleSendPasswordReminderSubmit}
              onValid={enableButton}
              onInvalid={disableButton}
            >
              <Form.Input
                name="email"
                label="Email"
                validations="isEmail"
                required={true}
              />
              <p>
                If you are having any other problems please contact{" "}
                <a
                  href="mailto:support@weightplan.com"
                  style={{
                    textDecoration: "underline",
                    color: "#cd0769",
                  }}
                >
                  Weightplan support
                </a>
                .
              </p>
              <Button type="submit" primary={true} disabled={!canSubmit}>
                Continue
              </Button>
            </Form>
          </div>
        </RetrievePassword>
      </Dialog>
      {wpDialogShow && (
        <WeightplanDialog
          body={wpDialogBody}
          buttons={wpDialogButtons}
          requestClose={handleRequestCloseWPDialog}
        />
      )}
    </React.Fragment>
  )
}

interface IRetrievePasswordFormData {
  email: string
}

export default RetrievePasswordDialog
