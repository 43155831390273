import React from "react"

const TermsAndConditions: React.FC = () => (
  <React.Fragment>
    <h1>Weightplan.com &amp; GymTraining App Terms and Conditions</h1>

    <h2>General</h2>

    <p>
      By accepting these terms and conditions you are entering into a legally
      binding contract. It is therefore important that you read these terms and
      conditions carefully. If you do not agree to any part of these terms, then
      please do not proceed. Completion of the contract between you and us will
      take place when we notify you that your registration has been accepted. To
      register you must be over eighteen years of age.
    </p>

    <h2>Interpretation</h2>

    <p>
      Headings do not form part of these terms and conditions and will not
      affect their interpretation.
    </p>

    <p>In these terms and conditions, any reference to:</p>

    <ul>
      <li>"the App" means the Weightplan application for mobile devices</li>
      <li>"the Site" means Weightplan.com</li>
      <li>"We" or "Us" means Weightplan Limited</li>
      <li>"You" or "Your" means you as the member of the Site or the App</li>
    </ul>

    <p>The Site and the App are owned and operated wholly by Us.</p>

    <h2>Personal Details</h2>

    <p>
      You must ensure that any details provided are correct and complete, and
      inform us immediately of any changes to this information.
    </p>

    <p>
      You must give us your name, address, phone number, email address and any
      other details that we may require to process your membership or administer
      your account.
    </p>

    <h2>Member conduct:</h2>

    <p>You must not:</p>

    <ul>
      <li>
        post, send, email, store, upload, or link to any material or content
        that is or may be offensive, illegal, unlawful or discriminate against
        any persons race, ethnicity, gender, disability, religion, beliefs,
        sexual orientation or any other preferences;
      </li>
      <li>interfere with another's enjoyment of the site;</li>
      <li>
        impersonate any person or falsely state or otherwise misrepresent your
        affiliation with a person;
      </li>
      <li>
        use the site for advertising goods or services without our written
        consent;
      </li>
      <li>
        upload to any of the site or do anything that breaches these terms and
        conditions;
      </li>
      <li>
        copy, modify, create a derivative work of, reverse engineer or assemble
        or otherwise abuse our rights in relation to any of our software;
      </li>
      <li>
        attempt to access any area of the site for which you are not authorised
        or access our system without our written consent;
      </li>
      <li>
        do anything which materially affects (or may materially affect) the
        functionality of our systems or the site.
      </li>
    </ul>

    <p>
      We reserve the right (with or without notice or justification in our
      absolute discretion but not so that we are obliged to exercise any right)
      to:
    </p>

    <ul>
      <li>take down or alter any material you upload to the sites;</li>
      <li>decline your registration;</li>
      <li>suspend your membership at any time;</li>
      <li>
        cancel your membership at any time - fees will not be reimbursed unless
        we are unable to justify the cancellation;
      </li>
      <li>terminate your access to all or part of the Site or the App;</li>
      <li>charge for any trial membership period;</li>
      <li>
        discontinue the Site or the App, or any parts thereof, at any time;
      </li>
      <li>
        read your messages or review your data and copy any such information
        that may be evidence of breach of these terms and conditions by you or
        any other member;
      </li>
      <li>refuse to post material on the Site;</li>
      <li>remove material posted on the Site.</li>
    </ul>

    <h2>Membership fees and pricing</h2>

    <p>
      You agree to provide us with true, accurate and complete information as
      required by the subscription or registration process including your name,
      address, postcode, e-mail address and applicable billing information (e.g.
      credit/debit card number and expiry date) and to allow us to pass this
      information to a third party payment processor for the purpose of
      verifying the information you provide and billing your credit/debit card.
    </p>

    <p>
      You agree to pay, using a valid credit/debit card (or other form of
      payment which we may accept from time to time) the membership fee for your
      chosen membership period at the rates shown on this site.
    </p>

    <p>
      You agree that we may charge the credit card (or other form of payment, if
      applicable) that you have given us details of. In the event that we cannot
      charge your card, we reserve the right to suspend or terminate your
      membership at our absolute discretion.
    </p>

    <p>
      You will receive an email receipt for any charges to your card on the date
      the payment is taken.
    </p>

    <p>The charge will appear on your card statement as: WEIGHTPLAN LIMITED.</p>

    <p>
      At the end of your current membership period (on your renewal date) your
      membership will automatically be renewed at the current monthly membership
      price unless you select an extended 3 month, 6 month or 12 month
      membership plan, or cancel your membership prior to the renewal date. If
      we are unable to process payment, your membership will be suspended until
      you have paid your membership fee. For billing purposes a month is assumed
      to be a period of 30 days.
    </p>

    <p>
      We reserve the right to increase membership fees at any time upon
      providing you with at least two weeks' notice in advance to give you time
      to decide whether you wish to continue with your subscription. If you do
      not wish to continue you should let us know and we shall cancel your
      membership at the end of your current membership period.
    </p>

    <p>
      If your credit/debit card expires, is cancelled, lost or is subject to use
      without your authorisation you agree to promptly notify us by writing to{" "}
      <a href="mailto:support@weightplan.com">support@weightplan.com</a>, and
      when requested, to provide details of an alternative current, valid
      credit/debit card (or other form of payment which we may accept from time
      to time).
    </p>

    <p>
      Where we discover an error in the prices quoted on the Site or the App, we
      will inform you as soon as possible and give you the option of
      reconfirming your registration at the correct price or cancelling it. If
      we are unable to contact you we will treat the registration as cancelled.
    </p>

    <p>All prices include UK VAT (where applicable).</p>

    <h2>Cancellation and refunds</h2>

    <p>
      Where you select a trial membership, you have the right to cancel your
      membership before the end of the trial period. This includes your right to
      cancel your membership under the Distance Selling Regulations.
    </p>

    <p>
      Please note that by commencing a full fee-paying membership immediately,
      you lose your right to cancel your membership and demand a full or partial
      refund.
    </p>

    <p>
      You may notify us that you wish to cancel your membership at any time from
      the ‘Easy Cancellation’ section under ‘My Account’ or by writing to
      support@weightplan.com.
    </p>

    <p>
      Your cancellation will be effective from your next renewal date. You will
      not be entitled to a refund for any membership fees already paid.
    </p>

    <p>Your statutory rights are not affected.</p>

    <h2>Password use</h2>

    <p>
      On becoming a member you choose your password. You must not disclose your
      password to anyone.
    </p>

    <p>
      We will never ask you for your password other than in logging in to the
      Site or the App.
    </p>

    <p>
      You are responsible for all acts done in your account name and therefore
      for the acts of anyone you give your password to in breach of these terms
      and conditions.
    </p>

    <h2>Reporting site abuses</h2>

    <p>
      We ask for your help in maintaining the high standards of the Site and the
      App and the communities within them by immediately notifying us of any
      material or communications that are in breach of these terms or that are,
      or appear to be, illegal.
    </p>

    <h2>Privacy policy</h2>

    <p>
      Our privacy policy applies to all personal data you submit to us. It can
      be found by clicking the link at the bottom of the homepage.
    </p>

    <h2>Email address and mailings</h2>

    <p>
      Everybody likes to take advantage of special offers or free information
      and advice. Through our mailings we try to keep our customers informed,
      and aim to keep these relevant and informative – without being intrusive.
      When you register with Weightplan we will include you in our mailing list
      to ensure you immediately know about the most generous membership offers
      or the latest professional fitness and nutrition advice. However, we do
      understand that you may have a few questions to ask us, so please check
      our FAQs for more information. If you’d prefer not to receive mailings you
      can change your settings at any time in ‘My Account’.
    </p>

    <h2>Personal use only</h2>

    <p>
      Any material on the Site or the App is strictly for personal use and must
      not under any circumstance be used by any user for commercial purposes or
      used in a defamatory or derogatory manner.
    </p>

    <h2>Your warranties</h2>

    <p>
      You warrant that all material you upload to any of the Site or the App:
    </p>

    <ul>
      <li>
        is true, accurate and up-to-date (and you agree to maintain it as such
        at all times);
      </li>
      <li>
        is your own creation/property or that you have consent to upload it to
        the Site or the App and in all cases that uploading the material does
        not breach any third party's intellectual property rights;
      </li>
      <li>
        is not defamatory, illegal, unlawful, offensive or discriminatory in any
        way;
      </li>
      <li>
        has been processed by up-to-date virus-checking software and found to be
        free of any virus, worm, Trojan or other similar device or effect that
        alters or may alter the functioning of any of the Site or the App or any
        computer system at any time.
      </li>
    </ul>

    <h2>Your Indemnity</h2>

    <p>
      You agree to indemnify us against any costs, claims, liabilities or
      expenses arising out of breach of these terms and condition and to keep us
      so indemnified at all times.
    </p>

    <h2>Waiver</h2>

    <p>
      The failure to insist upon or enforce the strict performance by you with
      respect to any provision of this agreement, or to exercise any right under
      this agreement, will not be construed as a waiver or relinquishment to any
      extent of our right to assert or rely upon any such provision or right in
      that or any other instance; rather, the same will be and remain in full
      force and effect.
    </p>

    <h2>Third Party Rights</h2>

    <p>
      You agree that any of our related companies may rely on and enforce these
      terms, but otherwise we and you agree that no other third parties are to
      be given any rights under the Contracts (Rights of Third Parties) Act
      1999.
    </p>

    <h2>Assignment</h2>

    <p>
      You agree that we may assign or novate these terms to anyone without
      notice to you.
    </p>

    <h2>Monitoring of content</h2>

    <p>
      Although we do not actively monitor submissions to the Site or the App or
      content posted by users and accept no responsibility or liability for
      this, submission and content posted may be subject to real-time vetting
      and monitoring.
    </p>

    <p>
      We reserve the right to amend or remove content without justification. In
      particular, we may do so if we receive a complaint about content, feel the
      content is unsuitable, in violation of the law, contains political content
      or in any way breach of these terms and conditions.
    </p>

    <p>
      By submitting material to the Site or the App you grant us a licence to
      reproduce, change, distribute or sub-licence the material in whole or in
      part and in any form.
    </p>

    <h2>Ownership of trademarks etc. on this website</h2>

    <p>
      We are the proprietor of the Site and the App and any associated
      trademarks, whether registered or not.
    </p>

    <p>
      All rights, including copyright and database rights, in the Site and the
      App and the content thereof are owned or controlled by us.
    </p>

    <p>
      We retain all copyright, database rights and other intellectual property
      rights in material contained on or in relation to the Site and the App.
    </p>

    <p>
      Except where expressly stated to the contrary, nothing in this website
      confers any licence or right to use any trademark displayed on this
      website without our prior written approval or the owner of the relevant
      trademark.
    </p>

    <p>
      All other trademarks, brand names, product names and titles and copyrights
      used in this site are trademarks, brand names, product names or copyrights
      of their respective holders. No permission is given by Us in respect of
      the use of any of them and such use may constitute an infringement of the
      holder's rights.
    </p>

    <p>
      The use of any trademark or intellectual property owned by any third party
      should not be treated as an endorsement of the Site or the App by any such
      third party.
    </p>

    <p>
      The use of any trademark or intellectual property owned by any third party
      should not be treated as an endorsement of that third party’s goods or
      services by Us.
    </p>

    <p>
      You may not copy, reproduce, download, republish, extract, broadcast,
      transmit, display, modify or re-use any of the materials on the Site or
      the App for any other purpose.
    </p>

    <p>
      We reserve the right to notify members via email of promotional offers,
      news and other information - we will only do so if members have opted-in
      to receive such communications.
    </p>

    <h2>Downtime</h2>

    <p>
      We may occasionally have to perform necessary systems maintenance and/or
      upgrades. These specified time periods are referred to as "Scheduled
      Maintenance Windows".
    </p>

    <p>
      In the event of plans to bring down the Site or the App during a Scheduled
      Maintenance Window, we try to provide 24 hours’ notice to our members in
      advance of the Scheduled Maintenance Window.
    </p>

    <p>
      We reserve the right to perform any required maintenance work outside of
      the Scheduled Maintenance Window with a minimum of 12 hours’ notice to
      members.
    </p>

    <p>
      Members must be aware that, at any time, we may perform emergency
      maintenance as needed to preserve the overall integrity of the Site or the
      App with no notice and with without compensation for such activities.
    </p>

    <p>
      We reserve the right to alter or suspend the Site or the App, or any parts
      thereof, at any time without prior notification or explanation.
    </p>

    <h2>Supported Browsers</h2>

    <p>
      We support most of the popular web browsers currently in use. However, we
      cannot guarantee that the specific browser you use will be able to display
      the Site or the App and all its features accurately. Please refer to our
      Help section for a full list of supported browsers.
    </p>

    <h2>Linking to the Site</h2>

    <p>
      Subject to acceptance of our linking terms users and others shall be
      entitled to set up hypertext links from their own websites to the home
      page only of the Site or App.
    </p>

    <h2>Exclusion of liability for linked websites</h2>

    <p>
      Whilst we exercise reasonable care in the selection of websites to which
      we link, we do not control their content, format or availability.
      Therefore:
    </p>

    <ul>
      <li>
        we give no warranties, endorsements or recommendations in relation to
        any products or services mentioned or any statements contained in third
        party sites;
      </li>
      <li>
        we disclaim all responsibility for any content you encounter, or events
        which may occur once you have transferred out of this website and are
        not be liable to you or anyone for any damages or other losses of any
        type whatsoever incurred by visiting a linked site.
      </li>
    </ul>

    <h2>Exclusion of liability for site content</h2>

    <p>
      You agree that whilst we take all reasonable measures to ensure the truth
      and accuracy of information on the Site or the App, we are not responsible
      for any wrong, inaccurate information whether it is posted by us or a
      member and you are advised to independently verify such information before
      relying on it. We also accept no responsibility for any misleading
      information posted by other members and accordingly are you are advised to
      proceed with caution in dealing with other members.
    </p>

    <p>
      We are not responsible for the accuracy of information posted by members
      on the Site or the App.
    </p>

    <p>
      Messages or content submitted on the Site or the App are the views and
      opinions of individual members, and do not necessarily reflect the views
      and opinions held by us, our advice or recommendations that we would make.
    </p>

    <p>
      We reserve the right to reproduce or modify any information posted by
      members on the Site or the App.
    </p>

    <h2>Use of the Site or the App</h2>

    <p>
      We recommend that you seek medical advice before starting any nutritional
      or exercise programme.
    </p>

    <p>
      You should not use this Site or App if you have a pre-existing medical
      condition that may preclude you from exercise or changing your diet, or if
      any of the following conditions apply to you: Pregnancy or breast feeding,
      asthma or allergies, high or low blood pressure, heart problems or angina,
      high cholesterol, thyroid disorder, arthritis or joint pain, diabetes
      (Type 1 or 2), wheat intolerance, gluten allergy, coeliac disease, recent
      surgery or medical treatment, anorexia or bulimia nervosa or other eating
      disorder, BMI &gt; 35, or on long term medication.
    </p>

    <p>
      The information provided on the Site and App is general guidance, and has
      not been tailored to your specific needs. By using the Site or the App you
      take full responsibility for ensuring that you are medically able to
      follow a nutrition and/or exercise plan. If you are at all unsure, you
      should consult your doctor for an individual assessment. We do not offer
      medical advice. You should not use this site if your doctor has advised
      you not to undertake exercise, or change your diet.
    </p>

    <p>
      By using the Site or the App you agree to carefully read all guidance and
      instructions and ensure you use the correct techniques and equipment that
      are appropriate to an exercise or nutritional plan.
    </p>

    <p>
      If you are following a meal plan with less than 1600 calories per day you,
      or restrict your calorie intake to below this level you should ensure that
      you maintain a healthy vitamin and mineral intake (within the recommended
      daily allowances), or use appropriate supplements. If you are at all
      unsure, you should consult your doctor for advice.
    </p>

    <p>
      We take every effort to select food items appropriate to the meal plans
      specified, however due to the constantly changing formulations of food and
      drink products we are unable to guarantee that all items in our meal plans
      are 100% wheat or allergen free. If you have specific dietary requirements
      you should confirm the ingredients and allergens advice on the pack, or
      with the manufacturers. If you are at all unsure, you should consult your
      doctor for advice.
    </p>

    <p>
      We do not warrant that you will lose or gain weight, or achieve your
      personal fitness goals by following the guidance contained on the Site or
      the App.
    </p>

    <p>
      You accept that no responsibility or liability is accepted by Weightplan
      Limited for any loss or damage suffered by you as a result of the use or
      misuse of any of the information contained on the Site or the App.
    </p>

    <h2>Exclusion of liability for viruses, etc</h2>

    <p>
      Whilst we take all reasonable precautions to protect the Site and the App
      and its users against worms, viruses and other harmful material, before
      downloading or using any potentially harmful material on the Site or the
      App, you are advised to ensure that your systems have up-to-date virus
      detecting software and have that software check the safety of any
      materials. Therefore, we do not accept responsibility for any defects,
      viruses or other harmful components that may be contained within any of
      the Site or the App.
    </p>

    <h2>Exclusion of liability for loss</h2>

    <p>
      To the extent permitted by applicable laws, no liability is accepted for
      any direct, indirect or consequential loss or damage or loss of use, data,
      business opportunity or profits, whether in an action in contract,
      negligence or other tortious action, however resulting from the access to
      and use of this website and the information and materials contained on it
      (but for the avoidance of doubt this clause does not exclude liability for
      death or personal injury).
    </p>

    <h2>Force Majeure</h2>

    <p>
      We shall not be responsible for lack of availability of the Site and/or
      the App and/or any other failures to perform in a timely manner due to
      events beyond our reasonable control including, without limitation, Acts
      of God or public enemies, civil war, insurrection or riot, fire, flood,
      explosion, earthquake, serious accident, failure of the Internet, strike,
      labour trouble or work interruption or any other cause beyond our
      reasonable control. In such circumstances the time for performance by us
      shall be extended for the duration of such events to the extent possible.
    </p>

    <h2>Termination of website use rights</h2>

    <p>
      A user's authorisation to use this website automatically terminates
      without notice if the user fails to comply with any of these terms. On
      termination, the user must cease all use of the Site and/or the App.
    </p>

    <h2>Right to amend these terms</h2>

    <p>
      We may at any time revise these terms by posting changes on the Site and
      the App. The continued use of the Site or the App after changes are posted
      constitutes your acceptance of the modified terms.
    </p>

    <h2>Entire Agreement</h2>

    <p>
      This Agreement, as updated from time to time, together with our Privacy
      Policy (if applicable), are the entire agreement between you and us
      relating to the subject matter herein and you acknowledge that, save as
      set out herein, you have not relied on any other warranties or
      representations whether made by us or any third party before entering into
      this Agreement.
    </p>

    <h2>Applicable laws</h2>

    <p>
      These terms are governed and construed in accordance with the laws of
      England.
    </p>

    <p>
      You agree to submit to the non-exclusive jurisdiction of the English
      Courts.
    </p>

    <p>
      If any of the terms is held to be unlawful, void or for any reason
      unenforceable, then that provision will be deemed severable and will not
      affect the validity and enforceability of the remaining terms.
    </p>

    <h2>Company details</h2>

    <p>
      Weightplan Limited
      <br />
      Company number 07222126 (Registered in England).
      <br />
      VAT number GB 998 3537 50
    </p>

    <h2>Registered address:</h2>

    <p>
      Weightplan Ltd, Avaland House, 110 London Road, Apsley, Hemel Hempstead,
      Herts. HP3 9SD United Kingdom
    </p>
  </React.Fragment>
)

export default TermsAndConditions
