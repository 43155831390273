import { post } from './http'

/**
 * Requests a password reset email
 * @param {string} email - The email address of the account which, if exists, should receive the reminder.
 * @return {Promise<void>}
 */
export const sendPasswordReminder = (email: string): Promise<void> => {
    if (email == null || email === "") {
        return Promise.reject(new Error("Email must be provided"))
    }

    return post("https://api.weightplan.com/User/RequestPassword", { email })
}

/**
 * Requests a password reset email
 * @param {string} id - The user id of the account which will have its password reset
 * @param {string} token - The password reset token
 * @param {string} password - The new password after reset
 * @return {Promise<void>}
 */
export const resetPassword = (id: string, token: string, password: string): Promise<void> => {
  return post("https://api.weightplan.com/User/ResetPassword", { id, token, password })
}
