import React from "react"
import styled from "styled-components"
import { v4 as uuid } from "uuid"

interface IProps {
  title?: string
  buttons?: IDialogButton[]
  requestClose: () => void
}

export interface IDialogButton {
  name: string
  action: () => void
}

export const CloseButton = styled.div`
  a {
    margin: -15px -15px 0 0;
    float: right;
    width: 30px;
    height: 30px;
  }
`

const DialogContainer = styled.div<{ zIndex: number }>`
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.zIndex};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DialogBox = styled.div`
  padding: 0;
  position: fixed;
  color: #222;
`

const Overlay = styled.div<{ zIndex: number }>`
  background-color: #000000;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.zIndex};
  height: 100%;
  opacity: 0.6;
`

const DialogTitlebar = styled.div`
  border-radius: 4px;
  border: 1px solid #aaa;
  color: #222;
  font-weight: bold;
  display: none;
`

const DialogContent = styled.div`
  min-height: 36px;
`

const DialogButtonPane = styled.div`
  border: 0 solid #000 !important;
  margin: 0 0 0 !important;
  padding: 0 !important;
  text-align: left;
`

const DialogButtonSet = styled.div`
  float: right;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
`

const Dialog: React.FC<IProps> = ({ title, buttons, children }) => {
  const dialogContainer = React.useRef<HTMLDivElement>(null)
  const uniqueId = uuid()

  const getMaxZIndex = () => {
    let highestZIndex = 2100
    const nodes = Array.from(document.querySelectorAll('[id^="dialog-"]'))

    nodes.forEach((dialog) => {
      const dialogDiv = dialog as HTMLDivElement
      const currentZIndex = Number(window.getComputedStyle(dialogDiv).zIndex)
      if (
        isVisible(dialogDiv) &&
        (dialogContainer.current == null ||
          dialogDiv.id !== dialogContainer.current.id) &&
        currentZIndex > highestZIndex
      ) {
        highestZIndex = currentZIndex
      }
    })

    return highestZIndex
  }

  const isVisible = (e: HTMLElement) => {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
  }

  return (
    <React.Fragment>
      <DialogContainer
        id={`dialog-${uniqueId}`}
        ref={dialogContainer}
        zIndex={getMaxZIndex() + 100}
      >
        <DialogBox>
          <DialogTitlebar>{title}</DialogTitlebar>
          <DialogContent>{children}</DialogContent>
          <DialogButtonPane>
            <DialogButtonSet>
              {buttons &&
                buttons.map((button) => (
                  <button
                    key={button.name}
                    type="button"
                    onClick={button.action}
                  >
                    {button.name}
                  </button>
                ))}
            </DialogButtonSet>
          </DialogButtonPane>
        </DialogBox>
      </DialogContainer>
      <Overlay id={`uiOverlay-${uniqueId}`} zIndex={getMaxZIndex() + 90} />
    </React.Fragment>
  )
}

export default Dialog
